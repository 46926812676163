import React from "react"
import { Layout } from "../components/common"
import Classics from "../components/Classics/index"
import { Helmet } from "react-helmet"
const classics = () => {
  return (
    <Layout>
      <Helmet>
        <title>Classics</title>
      </Helmet>
      <Classics />
    </Layout>
  )
}

export default classics
